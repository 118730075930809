import {createSlice} from "@reduxjs/toolkit";

export const INITIAL_SETUP = {
    usersPerRow: 10,
    columnCount: 1,
}

export const setupSlice = createSlice({
    name: 'setup',

    initialState: INITIAL_SETUP,

    reducers: {
        updateSetup: (state, action) => {
            const {usersPerRow, columnCount} = action.payload;
            state.usersPerRow = usersPerRow;
            state.columnCount = columnCount;
        },
    }
});

export const {
    updateSetup
} = setupSlice.actions;

export const setupReducer = setupSlice.reducer;
import {Component} from "react";
import {ConfirmModal} from "../../../widgets/ConfirmModal";

export class DeleteUserModal extends Component {

    render() {
        return (
            <ConfirmModal title={'Delete user ' + this.props.user.login}
                          buttonLabel={'Delete user'}
                          onClose={() => this.props.onClose()}
                          onConfirm={() => this.props.onDelete()}
                          body={'Are you sure you want to delete this user? In most cases, hiding from the tierlist ' +
                              'by unchecking \'Active\' should be sufficient.'}/>
        );
    }

}
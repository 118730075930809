import {Component} from "react";
import {Modal} from "react-bootstrap";
import {VrgButton} from "../../../widgets/VrgButton";
import './UserMoveModal.css';
import UserIcon from "../../../widgets/UserIcon";
import {connect} from "react-redux";
import {TierName} from "../tier/TierName";
import FormRange from "react-bootstrap/FormRange";
import {transferUser, transferUserFromStorage, transferUserToStorage, updatePosition} from "../../../store/slices/TierSlice";
import {mobile} from "../../../service/CssUtils";

class UserMoveModal extends Component {

    state = {
        updatedPosition: this.props.userTier != null && this.props.userTier.users.findIndex(user => user.id === this.props.user.id)
    }

    getPositionString() {
        return (this.state.updatedPosition + 1) + "/" + this.props.userTier.users.length;
    }

    updatePosition() {
        if (this.props.userTier && this.props.userTier.users.length > 1) {
            this.props.updatePosition({
                tier: this.props.userTier,
                user: this.props.user,
                position: this.state.updatedPosition
            });
        }
    }

    updatePositionAndClose() {
        this.updatePosition();
        this.props.onClose();
    }

    moveToTier(event, tier) {
        event.preventDefault();
        this.updatePosition();
        if (this.props.userTier) {
            this.props.transferUser({
                from: this.props.userTier.id,
                to: tier.id,
                user: this.props.user,
            });
        } else {
            this.props.transferUserFromStorage({
                to: tier.id,
                user: this.props.user,
            });
        }
        this.props.onClose();
    }

    removeFromCurrent() {
        this.updatePosition();
        if (this.props.userTier) {
            this.props.transferUserToStorage({
                from: this.props.userTier.id,
                user: this.props.user,
            });
        }
        this.props.onClose();
    }

    render() {
        return (
            <div>
                <Modal show={true} onHide={() => this.updatePositionAndClose()} size={'lg'} centered={true}
                       contentClassName={'user-move-modal-main'}>
                    <Modal.Header className={'user-move-modal-title-container'}>
                        <div className={'user-move-modal-title'}>
                            Pick a tier for {this.props.user.name}
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={'user-move-modal-body'}>
                            <div className={'user-move-modal-user-container'}>
                                <UserIcon user={this.props.user} ignoreMobile={true} noCloning={true}/>
                                {this.props.userTier ?
                                    <div className={'user-move-modal-tier-container'}>
                                        <div>is in tier</div>
                                        <TierName tier={this.props.userTier} disabled={true}/>
                                    </div>
                                    :
                                    <div>is not currently in any tier</div>
                                }
                            </div>
                            {this.props.userTier && this.props.userTier.users.length > 1 &&
                                <div className={'user-move-modal-position-container'}>
                                    <div className={'user-move-modal-position'}>
                                        Position: {this.getPositionString()}
                                    </div>
                                    <FormRange className={mobile('user-move-modal-range')} value={this.state.updatedPosition}
                                               onChange={(event) => this.setState({
                                                   updatedPosition: parseInt(event.target.value)
                                               })}
                                               min={0} max={this.props.userTier.users.length - 1}/>
                                </div>
                            }
                            <div className={'user-move-modal-tier-picker'}>
                                {this.props.tiers.map(tier => {
                                    return (
                                        <div key={tier.id} onClick={(event) => this.moveToTier(event, tier)}>
                                            <TierName tier={tier} disabled={true}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={'user-move-modal-buttons-container'
                            + (!this.props.userTier ? ' user-move-modal-single-button' : '')}>
                            {this.props.userTier &&
                                <VrgButton variant="danger" onClick={() => this.removeFromCurrent()}>
                                    Remove from tier
                                </VrgButton>
                            }
                            <VrgButton onClick={() => this.updatePositionAndClose()}>
                                Save
                            </VrgButton>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

}

function mapStateToProps(state, ownProps) {
    return {
        tiers: state.tier.tiers,
        userTier: state.tier.tiers.find(tier => tier.users.map(user => user.id).includes(ownProps.user.id))
    }
}

export default connect(mapStateToProps, {
    transferUser,
    transferUserToStorage,
    updatePosition,
    transferUserFromStorage
})(UserMoveModal)
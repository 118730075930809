import {createSlice} from "@reduxjs/toolkit";

export const adminSlice = createSlice({
    name: 'admin',

    initialState: {
        users: [],
    },

    reducers: {
        loadAdminUsers: (state, action) => {
            state.users = action.payload;
        },

        removeUser: (state, action) => {
            state.users = state.users.filter(user => user.id !== action.payload.id)
        },

        addUser: (state, action) => {
            state.users.push(action.payload);
        }
    }
});

export const {
    loadAdminUsers, removeUser, addUser
} = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
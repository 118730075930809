import {Component} from "react";
import {Modal} from "react-bootstrap";
import {VrgButton} from "./VrgButton";
import './ConfirmModal.css';

export class ConfirmModal extends Component {

    saveAndClose() {
        this.props.onConfirm();
        this.props.onClose();
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.props.onClose()} contentClassName={'confirm-modal-main'} centered={true}>
                <Modal.Header className={'confirm-modal-title-container'}>
                    <div className={'confirm-modal-title'}>
                        {this.props.title}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className={'confirm-modal-body'}>
                        {this.props.body}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'confirm-modal-buttons-container'}>
                        <VrgButton variant="secondary" onClick={() => this.props.onClose()}>
                            Cancel
                        </VrgButton>
                        <VrgButton variant={this.props.safe ? 'primary' : 'danger'} onClick={() => this.saveAndClose()}>
                            {this.props.buttonLabel}
                        </VrgButton>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

}
import {Component} from "react";
import {FormCheck, Modal} from "react-bootstrap";
import FormRange from "react-bootstrap/FormRange";
import {VrgButton} from "../../../widgets/VrgButton";
import {connect} from "react-redux";
import './RandomModal.css';
import {randomizeUsers, RandomMode} from "../../../store/slices/TierSlice";

const options = [
    {mode: RandomMode.Random, label: 'Pick users at random'},
    {mode: RandomMode.First, label: 'Randomize the first users in the list'},
]

class RandomModal extends Component {

    state = {
        randomCount: this.props.storage.length,
        mode: RandomMode.Random
    }

    randomizeAndClose() {
        this.props.randomizeUsers({count: this.state.randomCount, mode: this.state.mode});
        this.props.onClose();
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.props.onClose()} contentClassName={'random-modal-main'} centered={true}>
                <Modal.Header className={'random-modal-title-container'}>
                    <div className={'random-modal-title'}>
                        Tierlist randomizer
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className={'random-modal-body'}>
                        <span className={'random-modal-form-title'}>
                            Users randomized: {this.state.randomCount}
                        </span>
                        <FormRange value={this.state.randomCount} min={1} max={this.props.storage.length}
                                   className={'random-modal-range'}
                                   onChange={(event) => this.setState({randomCount: parseInt(event.target.value)})}/>
                        <span className={'random-modal-subtext'}>
                            How many unassigned users should be placed randomly.
                        </span>
                        <div className={'random-modal-spacer'}/>

                        <span className={'random-modal-form-title'}>
                            Randomization mode
                        </span>
                        {options.map(opt => (
                            <FormCheck type={'radio'} checked={opt.mode === this.state.mode} name={'mode'} key={opt.mode}
                                       label={opt.label} onChange={() => this.setState({mode: opt.mode})}
                            />
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'random-modal-buttons-container'}>
                        <VrgButton variant="secondary" onClick={() => this.props.onClose()}>
                            Cancel
                        </VrgButton>
                        <VrgButton className={'random-modal-save-button'} variant="primary"
                                   onClick={() => this.randomizeAndClose()}>
                            Randomize
                        </VrgButton>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        storage: state.tier.storage
    }
}

export default connect(mapStateToProps, {randomizeUsers})(RandomModal)
import {Component} from "react";
import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {TierlistPage} from "./pages/tierlist/TierlistPage";
import ProfilePage from "./pages/profile/ProfilePage";
import AdminPage from "./pages/admin/AdminPage";
import UserCreationPage from "./pages/admin/UserCreationPage";

export class App extends Component {

    componentDidMount() {
        document.title = '/vrg/ Tierlist'
    }

    render() {
        return (
            <div className={'app-root d-flex flex-column'}>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/">
                            <TierlistPage/>
                        </Route>
                        <Route path="/profile">
                            <ProfilePage/>
                        </Route>
                        <Route path="/admin">
                            <AdminPage/>
                        </Route>
                        <Route path="/user-create">
                            <UserCreationPage/>
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }

}

import {Component} from "react";
import './TierName.css'
import {mobile} from "../../../service/CssUtils";
import {VrgFormControl} from "../../../widgets/VrgFormControl";

export class TierName extends Component {

    getMainClass() {
        if (this.props.ignoreMobile) {
            return 'tier-name-container';
        }
        return mobile('tier-name-container')
    }

    render() {
        return (
            <div className={this.getMainClass()} style={this.props.tier.img != null ? {} : {background: this.props.tier.color}}>
                {!this.props.tier.img && !this.props.disabled &&
                    <VrgFormControl disabled={this.props.disabled} className={mobile('tier-name-input')}
                                    as={'textarea'}
                                    style={{background: this.props.tier.color}}
                                    value={this.props.tier.label}
                                    onChange={(event) => this.props.onLabelChange(event.target.value)}
                    />
                }
                {!this.props.tier.img && this.props.disabled &&
                    <div>{this.props.tier.label}</div>
                }
                {this.props.tier.img &&
                    <img src={this.props.tier.img} alt={this.props.tier.label} className={'tier-name-image'}/>
                }
            </div>
        )
    }
}
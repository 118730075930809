export const IS_MOBILE = 'ontouchstart' in window;
export const IS_DESKTOP = !IS_MOBILE;

export function mobile(original) {
    if (IS_MOBILE) {
        for (const cssClass of original.split(" ")) {
            original += ' ' + cssClass + '-mobile';
        }
    }
    return original;
}
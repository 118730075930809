import {Component} from "react";
import './TierColorPicker.css'
import {RgbStringColorPicker} from "react-colorful";
import {TierName} from "../TierName";
import {VrgFormControl} from "../../../../widgets/VrgFormControl";
import {TierColors} from "./TierColors";

export class TierColorPicker extends Component {

    state = {
        colors: TierColors
    }

    getColor(position) {
        return this.props.tier.color.substring(4, this.props.tier.color.length - 1).split(", ")[position];
    }

    replaceColor(position, value) {
        if (value < 0 || value > 255) return;
        const currentRGB = this.props.tier.color.substring(4, this.props.tier.color.length - 1).split(", ");
        currentRGB[position] = value;
        this.props.onColorChange("rgb(" + currentRGB.join(", ") + ")")
    }

    render() {
        return (
            <div className={'tier-color-picker-container'}>
                <div className={'tier-color-picker-name-container'}>
                    <TierName tier={this.props.tier} disabled={true}/>
                </div>
                <div className={"tier-color-circles-container"}>
                    {this.state.colors.map(
                        (color, index) =>
                            <span
                                style={{background: color}} key={index}
                                onClick={() => this.props.onColorChange(color)}
                                className={"tier-color-circle" +
                                    (color === this.props.tier.color ? ' tier-color-selected' : '')}/>
                    )}
                </div>
                <RgbStringColorPicker color={this.props.tier.color}
                                      onChange={(event) => this.props.onColorChange(event)}/>

                <div className={'tier-color-picker-rgb-container'}>
                    <span>R</span>
                    <VrgFormControl onChange={(event) => this.replaceColor(0, event.target.value)}
                                    className={'tier-color-picker-input'}
                                    value={this.getColor(0)}/>
                    <span>G</span>
                    <VrgFormControl onChange={(event) => this.replaceColor(1, event.target.value)}
                                    className={'tier-color-picker-input'}
                                    value={this.getColor(1)}/>
                    <span>B</span>
                    <VrgFormControl onChange={(event) => this.replaceColor(2, event.target.value)}
                                    className={'tier-color-picker-input'}
                                    value={this.getColor(2)}/>
                </div>

            </div>

        )
    }
}
import {Component} from "react";
import './ProfilePage.css';
import LoginForm from "./login/LoginForm";
import {ProfileInfo} from "./login/ProfileInfo";
import {connect} from "react-redux";
import {UserSettings} from "./settings/UserSettings";
import {VrgPage} from "../VrgPage";
import {login, logout} from "../../store/slices/ProfileSlice";
import {baseUrl} from "../../../config";
import {getAuthPutRequest} from "../../service/FetchUtils";
import {mobile} from "../../service/CssUtils";
import {StatusState} from "../../widgets/StatusDisplay";

class ProfilePage extends Component {

    state = {
        status: ''
    }

    saveChanges(data) {
        this.setState({status: StatusState.Loading})
        fetch(`${baseUrl}/users/${this.props.loggedUser.id}`, getAuthPutRequest(data, this.props.loggedUser))
            .then(response => response.json())
            .then(data => {
                this.props.login(data);
                this.setState({status: StatusState.Done});
            })
            .catch(() => this.setState({status: StatusState.Errored}));
    }

    render() {
        return (
            <VrgPage page={'profile'}>
                {
                    this.props.loggedUser ?
                        <UserSettings user={this.props.loggedUser}
                                      cancelLabel={'Log out'}
                                      status={this.state.status}
                                      onSuccess={(data) => this.saveChanges(data)}
                                      onCancel={() => this.props.logout()}
                        /> :
                        <div className={mobile('profile-page-split')}>
                            <div className={'profile-left-panel'}>
                                <LoginForm/>
                            </div>
                            <div className={'profile-right-panel'}>
                                <ProfileInfo/>
                            </div>
                        </div>
                }
            </VrgPage>
        )
    }
}

function mapStateToProps(state) {
    return {
        loggedUser: state.profile.loggedUser
    }
}

export default connect(mapStateToProps, {login, logout})(ProfilePage)
import {Component} from "react";
import {FormControl} from "react-bootstrap";
import './VrgFormControl.css';

// noinspection JSValidateTypes
export class VrgFormControl extends Component {

    render() {
        return (
            <FormControl {...this.props} className={'vrg-form-control ' + this.props.className}/>
        )
    }

}
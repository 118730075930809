import {Component, createRef} from "react";
import {Modal} from "react-bootstrap";
import './TierEditModal.css';
import {VrgFormControl} from "../../../../widgets/VrgFormControl";
import {TierColorPicker} from "./TierColorPicker";
import {VrgButton} from "../../../../widgets/VrgButton";
import {resizeFile} from "../../../../service/ImageResizer";
import {connect} from "react-redux";
import {addTier, deleteEditedTier, editTier, updateTier} from "../../../../store/slices/TierSlice";

class TierEditModal extends Component {

    fileInputRef = createRef();

    state = {
        updatedTier: this.props.tier
    }

    updateTierState(props) {
        this.setState({
            updatedTier: {
                ...this.state.updatedTier,
                ...props
            }
        });
    }

    doAndClose(action) {
        if (action) {
            action();
        }
        this.props.editTier(undefined);
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.doAndClose()} contentClassName={'tier-modal-main'} size={'lg'}>
                <Modal.Header className={'tier-modal-title-container'}>
                    <div className={'tier-modal-title'}>
                        Edit tier {this.state.updatedTier.label}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className={'tier-modal-body'}>
                        <span className={'tier-modal-form-title'}>Label</span>
                        <VrgFormControl value={this.state.updatedTier.label}
                                        onChange={(event) => this.updateTierState({label: event.target.value})}>
                        </VrgFormControl>
                        <div className={'tier-modal-spacer'}/>

                        <span className={'tier-modal-form-title'}>Color</span>
                        <TierColorPicker onColorChange={(color) => this.updateTierState({color: color})}
                                         tier={this.state.updatedTier}/>
                        <div className={'tier-modal-spacer'}/>

                        <span className={'tier-modal-form-title'}>Actions</span>
                        <div className={'tier-modal-action-container'}>
                            <VrgButton className={'tier-modal-action'}
                                       onClick={() => this.props.addTier(this.props.tiers.findIndex(i => i.id === this.props.tier.id))}>
                                Add tier above
                            </VrgButton>
                            {this.state.updatedTier.img != null &&
                                <VrgButton className={'tier-modal-action'} variant={"danger"}
                                           onClick={() => this.updateTierState({img: undefined})}>
                                    Remove image
                                </VrgButton>
                            }
                            <VrgButton className={'tier-modal-action'}
                                       onClick={(event) => this.fileInputRef.current.click(event)}>
                                Upload image
                            </VrgButton>
                            <VrgButton className={'tier-modal-action'}
                                       onClick={() => this.props.addTier(this.props.tiers.findIndex(i => i.id === this.props.tier.id) + 1)}>
                                Add tier below
                            </VrgButton>
                        </div>

                        <input ref={this.fileInputRef} style={{display: 'none'}}
                               accept={'.png,.jpg,.jpeg'} type="file"
                               onChange={() => resizeFile(this.fileInputRef.current.files[0],
                                   (uri) => this.updateTierState({img: uri}))}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'tier-bottom-buttons-container'}>
                        <VrgButton variant="danger" onClick={() => this.props.deleteEditedTier()}>
                            Delete tier
                        </VrgButton>
                        <div>
                            <VrgButton variant="secondary" onClick={() => this.doAndClose()}>
                                Cancel
                            </VrgButton>
                            <VrgButton className={'tier-modal-save-button'} variant="primary"
                                       onClick={() => this.doAndClose(() => this.props.updateTier(this.state.updatedTier))}>
                                Save Changes
                            </VrgButton>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

}

function mapStateToProps(state) {
    return {
        tier: state.tier.tierEdited,
        tiers: state.tier.tiers,
    }
}

export default connect(mapStateToProps, {updateTier, deleteEditedTier, addTier, editTier})(TierEditModal)
import {Component} from "react";
import {Link} from "react-router-dom";

// noinspection JSValidateTypes
export class VrgRouterLink extends Component {

    render() {
        return (
            <Link {...this.props} style={{color: 'inherit', textDecoration: 'inherit'}}>
                {this.props.children}
            </Link>
        )
    }

}
import {Component} from "react";
import './ProfileInfo.css';

export class ProfileInfo extends Component {

    render() {
        return (
            <div className={'profile-info-container'}>
                <div className={'profile-info-title'}>
                    Why would i want to log in in the first place?
                </div>
                <div className={'profile-info-text'}>
                    Signing in allows you to update your profile picture and name.
                    You can also hide yourself from the tierlist if you no longer wish to be here.
                </div>

                <div className={'profile-info-title'}>
                    How do I get my credentials? / How do I add myself?
                </div>
                <div className={'profile-info-text'}>
                    You can login with either your current name or your original tierlist name.
                    To get your password, contact
                    <span className={'profile-info-highlight'}>
                        <span style={{color: 'blue'}}> french</span>
                        <span> maid</span>
                        <span style={{color: 'red'}}> S </span>
                    </span>
                    or
                    <span className={'profile-info-highlight'}> bird R</span>.
                </div>

                <div className={'profile-info-title'}>
                    Can I change my password?
                </div>
                <div className={'profile-info-text'}>
                    No. I'm not interested in storing any sensitive information.
                    If you've lost your password or it got compromised, ask us again and we'll regenerate a new one for
                    you.
                </div>
            </div>
        )
    }

}
import {Component} from "react";
import {connect} from "react-redux";
import {initUsers, restoreTiers, updateAvailableUsers, updateTitle} from "../../store/slices/TierSlice";
import {baseUrl} from "../../../config";
import {updateSetup} from "../../store/slices/SetupSlice";

export const STORE_COMPATIBILITY_VERSION = 2;

class TierlistInitializer extends Component {

    componentDidMount() {
        window.addEventListener('beforeunload', () => this.saveTierlist());
        try {
            const previousStateString = window.localStorage.getItem('state');
            if (!previousStateString) {
                this.startFromScratch();
                return;
            }
            const previousState = JSON.parse(previousStateString);
            if (previousState.version !== this.STORE_COMPATIBILITY_VERSION) {
                this.startFromScratch();
                return;
            }
            this.props.restoreTiers({tiers: previousState.tiers});
            this.props.updateSetup(previousState.setup);
            this.props.updateTitle(previousState.title);
            fetch(`${baseUrl}/users`).then(response => response.json())
                .then(data => this.props.updateAvailableUsers(data));
        } catch (e) {
            this.startFromScratch();
        }
    }

    startFromScratch() {
        fetch(`${baseUrl}/users`).then(response => response.json())
            .then(data => this.props.initUsers(data));
    }

    componentWillUnmount() {
        this.saveTierlist();
        window.removeEventListener('beforeunload', () => this.saveTierlist())
    }

    saveTierlist() {
        window.localStorage.setItem('state', JSON.stringify({
            tiers: this.props.tiers,
            version: this.STORE_COMPATIBILITY_VERSION,
            title: this.props.title,
            setup: this.props.setup,
        }));
    }

    render() {
        return (<div style={{visibility: 'hidden'}}/>)
    }
}

function mapStateToProps(state) {
    return {
        tiers: state.tier.tiers,
        title: state.tier.title,
        setup: state.setup,
    }
}

export default connect(mapStateToProps, {
    initUsers,
    restoreTiers,
    updateAvailableUsers,
    updateSetup,
    updateTitle,
})(TierlistInitializer)
import {Component} from "react";
import UserDragZone from "./user/UserDragZone";
import './TierlistPage.css'
import Tierlist from "./Tierlist";
import {VrgPage} from "../VrgPage";
import TierlistInitializer from "./TierlistInitializer";
import {mobile} from "../../service/CssUtils";

export class TierlistPage extends Component {

    render() {
        return (
            <VrgPage page={'tierlist'}>
                <TierlistInitializer/>
                <div className={mobile('tierlist-page-content')}>
                    <div className={mobile('tierlist-left-panel')}>
                        <Tierlist/>
                    </div>
                    <div className={mobile('tierlist-right-panel')}>
                        <UserDragZone storage={true}/>
                    </div>
                </div>
            </VrgPage>
        )
    }
}


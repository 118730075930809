function getJsonRequest(method, payload, headers = {}) {
    const base = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        }
    };
    if (method !== 'GET') {
        base.body = JSON.stringify(payload)
    }
    return base;
}

export function getPostRequest(payload) {
    return getJsonRequest('POST', payload);
}

export function getAuthGetRequest(user) {
    return getJsonRequest('GET', {}, getAuthHeaders(user));
}

export function getAuthPutRequest(payload, user) {
    return getJsonRequest('PUT', payload, getAuthHeaders(user));
}

export function getAuthPostRequest(payload, user) {
    return getJsonRequest('POST', payload, getAuthHeaders(user));
}

export function getAuthDeleteRequest(user) {
    return getJsonRequest('DELETE', {}, getAuthHeaders(user));
}

export function getAuthHeaders(user) {
    return {
        vrgUser: user.login,
        vrgPassword: user.password
    };
}


import {Component} from "react";
import {VrgPage} from "../VrgPage";
import {connect} from "react-redux";
import {baseUrl} from "../../../config";
import {getAuthHeaders} from "../../service/FetchUtils";
import './AdminPage.css';
import {withRouter} from "react-router-dom";
import {compose} from "@reduxjs/toolkit";
import UserTable from "./table/UserTable";
import {loadAdminUsers} from "../../store/slices/AdminSlice";

class AdminPage extends Component {

    state = {
        users: [],
        status: 'loading'
    }

    componentDidMount() {
        if (!this.props.loggedUser) {
            this.props.history.push('/profile');
        } else {
            fetch(`${baseUrl}/admin/users`, {headers: getAuthHeaders(this.props.loggedUser)})
                .then(response => response.json())
                .then(data => {
                    this.props.loadAdminUsers(data);
                    this.setState({status: 'done'});
                })
                .catch(() => this.setState({status: 'error'}));
        }
    }

    render() {
        return (
            <VrgPage page={'admin'}>
                {this.state.status === 'loading' &&
                    <div className={'admin-page-text'}>Loading...</div>
                }
                {this.state.status === 'error' &&
                    <div className={'admin-page-text admin-page-text-warning'}>Failed to load users</div>
                }
                {this.state.status === 'done' &&
                    <div className={'admin-table-wrapper'}>
                        <UserTable/>
                    </div>
                }
            </VrgPage>
        )
    }

}

function mapStateToProps(state) {
    return {
        loggedUser: state.profile.loggedUser
    }
}

export default compose(withRouter, connect(mapStateToProps, {loadAdminUsers}))(AdminPage)
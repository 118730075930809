import {createSlice} from "@reduxjs/toolkit";

export const searchSlice = createSlice({
    name: 'search',

    initialState: {
        filter: undefined
    },

    reducers: {
        updateSearchFilter: (state, action) => {
            state.filter = action.payload;
        },
    }
});

export const {
    updateSearchFilter
} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;
import {Component} from "react";
import './UserIcon.css'
import {imageUrl} from "../../config";
import {IS_DESKTOP, IS_MOBILE, mobile} from "../service/CssUtils";
import UserMoveModal from "../pages/tierlist/user/UserMoveModal";
import {cloneUser, removeClone} from "../store/slices/TierSlice";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faTrash} from "@fortawesome/free-solid-svg-icons";

class UserIcon extends Component {

    state = {
        userModalOpen: false
    }

    getImageClass() {
        if (this.props.ignoreMobile) {
            return 'user-icon-container';
        }
        return mobile('user-icon-container')
    }

    userAction() {
        if (this.props.cloningMode && !this.props.noCloning) {
            this.props.cloneUser(this.props.user);
        } else if (IS_MOBILE) {
            this.setState({userModalOpen: true});
        }
    }

    render() {
        return (
            <div>
                <div className={this.getImageClass()}
                     draggable={true}
                     style={this.props.user.img_path ?
                         {backgroundImage: `url("${imageUrl}/${this.props.user.img_path}")`} :
                         {}
                     }
                     title={this.props.user.name}
                     onClick={() => this.userAction()}
                     onDoubleClick={() => this.setState({userModalOpen: true})}
                >
                    {this.props.cloningMode && !this.props.noCloning &&
                        <div className={mobile('user-icon-action-wrapper') + ' user-icon-action-wrapper-copy'}>
                            <FontAwesomeIcon icon={faCopy} className={mobile('user-icon-action')}/>
                        </div>
                    }

                    {IS_DESKTOP && this.props.user.clone && !this.props.noCloning &&
                        <div className={mobile('user-icon-action-wrapper') + ' user-icon-action-wrapper-delete'}
                             onClick={() => this.props.removeClone(this.props.user)}>
                            <FontAwesomeIcon icon={faTrash} className={mobile('user-icon-action')}/>
                        </div>
                    }

                    {IS_MOBILE && this.props.user.clone && !this.props.noCloning &&
                        <div className={mobile('user-icon-action-wrapper') + ' user-icon-action-wrapper-delete'}
                             onTouchStart={() => this.props.removeClone(this.props.user)}>
                            <FontAwesomeIcon icon={faTrash} className={mobile('user-icon-action')}/>
                        </div>
                    }
                </div>
                {this.state.userModalOpen &&
                    <UserMoveModal user={this.props.user}
                                   onClose={() => this.setState({userModalOpen: false})}
                    />
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        cloningMode: state.tier.cloningMode
    }
}

export default connect(mapStateToProps, {cloneUser, removeClone})(UserIcon)
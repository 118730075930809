import {Component} from "react";
import './UserTable.css';
import {connect} from "react-redux";
import UserLine from "./UserLine";

class UserTable extends Component {

    render() {
        return (
            <div className={'user-table'}>
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Login</th>
                        <th>Password</th>
                        <th>Name</th>
                        <th>Active</th>
                        <th>Restricted</th>
                        <th>Picture</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.users.map((user) => {
                        return (
                            <UserLine key={user.id} user={user}/>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const filter = state.search.filter == null ? '' : state.search.filter.toLowerCase();
    return {
        users: state.admin.users.filter(user =>
            user.login.toLowerCase().includes(filter) || user.name.toLowerCase().includes(filter)
        )
    }
}

export default connect(mapStateToProps, {})(UserTable)
export const TierColors = [
    "rgb(255, 127, 127)",
    "rgb(255, 191, 127)",
    "rgb(255, 223, 127)",
    "rgb(255, 255, 127)",
    "rgb(191, 255, 127)",
    "rgb(127, 255, 127)",
    "rgb(127, 255, 255)",
    "rgb(127, 191, 255)",
    "rgb(127, 127, 255)",
    "rgb(255, 127, 255)",
    "rgb(191, 127, 191)",
    "rgb(59, 59, 59)",
    "rgb(133, 133, 133)",
    "rgb(207, 207, 207)",
    "rgb(247, 247, 247)",
];
import {Component} from "react";
import {VrgFormControl} from "../../../widgets/VrgFormControl";
import './LoginForm.css';
import {VrgButton} from "../../../widgets/VrgButton";
import {connect} from "react-redux";
import {login} from "../../../store/slices/ProfileSlice";
import {baseUrl} from "../../../../config";
import {getPostRequest} from "../../../service/FetchUtils";

class LoginForm extends Component {

    state = {
        login: '',
        password: '',
        errored: false
    }

    attemptLogin() {
        fetch(`${baseUrl}/users/login`, getPostRequest({login: this.state.login, password: this.state.password}))
            .then(response => response.json())
            .then(data => this.props.login(data))
            .catch(() => this.setState({errored: true}));
    }

    render() {
        return (
            <div className={'login-form-container'}>
                <span className={'login-form-title'}>Login</span>
                <VrgFormControl value={this.state.login} className={'login-form-input'} id={'username'}
                                onChange={(event) => this.setState({login: event.target.value})}/>
                <div className={'login-form-spacer'}/>
                <span className={'login-form-title'}>Password</span>
                <VrgFormControl value={this.state.password} className={'login-form-input'} id={'password'}
                                type={'password'}
                                onChange={(event) => this.setState({password: event.target.value})}/>
                <VrgButton className={'login-form-button'} onClick={() => this.attemptLogin()}>
                    Login
                </VrgButton>
                {this.state.errored &&
                    <div className={'login-form-error'}>Invalid credentials</div>
                }
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        loggedUser: state.profile.loggedUser
    }
}

export default connect(mapStateToProps, {login})(LoginForm)
import {Component} from "react";
import './StatusDisplay.css';

export const StatusState = {
    Loading: 'loading',
    Done: 'done',
    Errored: 'error'
}

export class StatusDisplay extends Component {

    render() {
        return (
            <div className={'status-container'}>
                {this.props.status === StatusState.Loading &&
                    <div className={'status-text'}>{this.props.loading}</div>
                }
                {this.props.status === StatusState.Done &&
                    <div className={'status-text status-success'}>
                        {this.props.done}
                        {this.props.subText &&
                            <div className={'status-subtext'}>
                                {this.props.subText}
                            </div>
                        }
                    </div>
                }
                {this.props.status === StatusState.Errored &&
                    <div className={'status-text status-error'}>{this.props.error}</div>
                }
            </div>
        )
    }

}
import {Component} from "react";
import UserIcon from "./UserIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
import './PicturePreviewer.css'

export class PicturePreviewer extends Component {

    render() {
        return (
            <div className={'picture-updater-container'}>
                <UserIcon user={this.props.user} ignoreMobile={true} noCloning={true}/>
                <FontAwesomeIcon icon={faArrowRightLong}/>
                <div style={{backgroundImage: `url(${this.props.img})`}}
                     className={'picture-updater-new-image'}
                />
            </div>
        )
    }
}
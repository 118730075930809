import {Component} from "react";
import UserIcon from "../../../widgets/UserIcon";
import './UserDragZone.css'
import {connect} from "react-redux";
import {dragUser, transferUserToStorage, updateDraggedUser, updateTier} from "../../../store/slices/TierSlice";
import {mobile} from "../../../service/CssUtils";

class UserDragZone extends Component {

    startDrag(event, user) {
        this.props.updateDraggedUser(user);
    }

    dragOnEmpty(e) {
        if (this.props.users.length > 0 && this.props.users[this.props.users.length - 1] !== this.props.draggedUser) {
            this.dragOver(e, this.props.users.length);
        } else {
            this.dragOver(e, this.props.users.length - 1);
        }
    }

    dragOver(event, index) {
        event.preventDefault();
        if (this.props.draggedUser && this.props.users[index] !== this.props.draggedUser) {
            this.props.dragUser({to: this.props.storage ? 'storage' : this.props.tier.id, index: index});
        }
    }

    drop(event) {
        event.preventDefault();
        this.props.updateDraggedUser(null);
    }

    shouldAlignStart() {
        return this.props.storage && this.props.users && this.props.users.length > 0;
    }

    getMainStyle() {
        return 'icons-container ' + (this.shouldAlignStart() ? 'icons-container-not-empty' : '');
    }

    render() {
        return (
            <div className={this.props.ignoreMobile ? this.getMainStyle() : mobile(this.getMainStyle())}>
                {this.props.users.map((user, index) => (
                    <div key={user.id}
                         onDragStart={(e) => this.startDrag(e, user)}
                         onDragOver={(e) => this.dragOver(e, index)}
                         onDrop={(e) => this.drop(e)}
                    >
                        <UserIcon user={user} ignoreMobile={this.props.ignoreMobile}/>
                    </div>
                ))}
                <div className={'user-drag-filler'}
                     onDragOver={(e) => this.dragOnEmpty(e)}
                     onDrop={(e) => this.drop(e)}
                />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    let users = ownProps.storage ? state.tier.storage : ownProps.tier.users;
    if (ownProps.storage && state.search.filter) {
        users = users.filter(i => i.name.toLowerCase().includes(state.search.filter.toLowerCase()));
    }
    return {
        users: users,
        draggedUser: state.tier.draggedUser,
    }
}

export default connect(mapStateToProps, {
    updateTier,
    updateDraggedUser,
    dragUser,
    transferUserToStorage
})(UserDragZone)
import {Component} from "react";
import Tier from "./tier/Tier";
import './Tierlist.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import TierEditModal from "./tier/edit/TierEditModal";
import {connect} from "react-redux";
import {editTier, moveTier} from "../../store/slices/TierSlice";
import {mobile} from "../../service/CssUtils";
import TierlistFooter from "./TierlistFooter";
import {withRouter} from "react-router-dom";
import {compose} from "@reduxjs/toolkit";
import {baseUrl} from "../../../config";
import {getPostRequest} from "../../service/FetchUtils";
import download from 'downloadjs';
import TierlistHeader from "./TierlistHeader";

class Tierlist extends Component {

    state = {
        status: '',
    }

    exportTierlist() {
        this.setState({status: 'loading'})
        fetch(`${baseUrl}/render`, getPostRequest({
            tiers: this.props.tiers,
            title: this.props.title,
            setup: this.props.setup,
        }))
            .then(response => response.blob())
            .then((blob) => {
                download(blob, 'tierlist.png', 'image/png')
                this.setState({status: ''})
            })
            .catch((err) => {
                console.error(err);
                this.setState({status: ''})
            });
    }

    render() {
        return (
            <div className={'tierlist-container'}>
                {this.props.tierEdited != null && <TierEditModal/>}
                <TierlistHeader/>
                <div className={'tierlist-tiers-container'}>
                    {this.props.tiers.map((tier) => (
                        <div key={tier.id} className={'tierlist-tier-container'}>
                            <Tier tier={tier}/>
                            <div className={'tierlist-actions-container'}>
                                <FontAwesomeIcon icon={faPenToSquare} className={mobile('tierlist-icon-gear')}
                                                 onClick={() => this.props.editTier(tier)}/>
                                <div className={'tierlist-tier-arrow-container'}>
                                    <FontAwesomeIcon
                                        icon={faArrowUp} className={mobile('tierlist-icon-arrow')}
                                        onClick={() => this.props.moveTier({tier, direction: 'up'})}
                                    />
                                    <FontAwesomeIcon
                                        icon={faArrowDown} className={mobile('tierlist-icon-arrow')}
                                        onClick={() => this.props.moveTier({tier, direction: 'down'})}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <TierlistFooter onExport={() => this.exportTierlist()} disableExport={this.state.status === 'loading'}/>
                <div className={mobile('tierlist-bottom-spacer')}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        tierEdited: state.tier.tierEdited,
        tiers: state.tier.tiers,
        setup: state.setup,
        title: state.tier.title,
    }
}

export default compose(withRouter, connect(mapStateToProps, {
    moveTier, editTier
}))(Tierlist)
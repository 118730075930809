import {Component, createRef} from "react";
import {FormCheck} from "react-bootstrap";
import {PicturePreviewer} from "../../../widgets/PicturePreviewer";
import {VrgButton} from "../../../widgets/VrgButton";
import './UserLine.css';
import {resizeFile} from "../../../service/ImageResizer";
import {VrgFormControl} from "../../../widgets/VrgFormControl";
import {baseUrl} from "../../../../config";
import {getAuthDeleteRequest, getAuthPostRequest, getAuthPutRequest} from "../../../service/FetchUtils";
import {connect} from "react-redux";
import {DeleteUserModal} from "./DeleteUserModal";
import {removeUser} from "../../../store/slices/AdminSlice";

class UserLine extends Component {

    fileInputRef = createRef();

    state = {
        user: {...this.props.user},
        base64Img: undefined,
        status: '',
        deleteModalOpen: false,
    }

    loadUploadedPicture() {
        resizeFile(this.fileInputRef.current.files[0], (uri) => this.setState({base64Img: uri}));
    }

    switchBooleanState(field) {
        this.setState({
            user: {
                ...this.state.user,
                [field]: !this.state.user[field]
            }
        });
    }

    saveChanges() {
        this.setState({status: 'loading'});
        fetch(`${baseUrl}/admin/users/${this.props.user.id}`, getAuthPutRequest({
            ...this.state.user,
            base64Img: this.state.base64Img == null ? undefined : this.state.base64Img
        }, this.props.loggedUser))
            .then(response => response.json())
            .then(data => {
                this.setState({status: 'done', user: data})
            })
            .catch(() => this.setState({status: 'error'}));
    }

    regeneratePassword() {
        this.setState({status: 'loading'});
        fetch(`${baseUrl}/admin/users/${this.props.user.id}/regen-password`,
            getAuthPostRequest({}, this.props.loggedUser))
            .then(response => response.json())
            .then(data => {
                this.setState({status: 'done', user: data})
            })
            .catch(() => this.setState({status: 'error'}));
    }

    deleteUser() {
        this.setState({status: 'loading'});
        fetch(`${baseUrl}/admin/users/${this.props.user.id}`, getAuthDeleteRequest(this.props.loggedUser))
            .then(response => response.json())
            .then(data => {
                this.props.removeUser(data)
            })
            .catch(() => this.setState({status: 'error'}));
    }

    render() {
        return (
            <tr className={'user-line-container'}>
                <td>{this.state.user.id}</td>
                <td>{this.state.user.login}</td>
                <td>{this.state.user.password}</td>
                <td>
                    <div className={'user-line-input-wrapper'}>
                        <VrgFormControl value={this.state.user.name}
                                        onChange={(event) => this.setState({
                                            user: {...this.state.user, name: event.target.value}
                                        })}/>
                    </div>
                </td>
                <td>
                    <FormCheck checked={this.state.user.active}
                               onChange={() => this.switchBooleanState('active')}/>
                </td>
                <td>
                    <FormCheck checked={this.state.user.restricted}
                               onChange={() => this.switchBooleanState('restricted')}/>
                </td>
                <td>
                    <PicturePreviewer user={this.state.user} img={this.state.base64Img}/>
                </td>
                <td>
                    <div className={'user-line-actions-wrapper'}>
                        <div className={'user-line-actions'}>
                            <VrgButton onClick={(event) => this.fileInputRef.current.click(event)}>
                                Upload PNG
                            </VrgButton>
                            <VrgButton onClick={() => this.saveChanges()}>
                                Save changes
                            </VrgButton>
                            <input ref={this.fileInputRef} style={{display: 'none'}} accept={'.png'}
                                   type="file" onChange={() => this.loadUploadedPicture()}/>
                            <VrgButton onClick={() => this.regeneratePassword()}>
                                Regenerate password
                            </VrgButton>
                            <VrgButton variant={'danger'} onClick={() => this.setState({deleteModalOpen: true})}>
                                Delete user
                            </VrgButton>
                            {this.state.deleteModalOpen &&
                                <DeleteUserModal user={this.state.user}
                                                 onClose={() => this.setState({deleteModalOpen: false})}
                                                 onDelete={() => this.deleteUser()}
                                />}
                        </div>
                        {this.state.status === 'loading' &&
                            <div className={'user-line-status'}>Updating profile...</div>}
                        {this.state.status === 'done' &&
                            <div className={'user-line-status user-line-status-success'}>
                                User updated.
                            </div>}
                        {this.state.status === 'error' &&
                            <div className={'user-line-status user-line-status-error'}>Failed to update</div>}
                    </div>
                </td>
            </tr>
        )
    }
}

function mapStateToProps(state) {
    return {
        loggedUser: state.profile.loggedUser
    }
}

export default connect(mapStateToProps, {removeUser})(UserLine)
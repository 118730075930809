import {Component} from "react";
import {connect} from "react-redux";
import {VrgFormControl} from "../widgets/VrgFormControl";
import {updateSearchFilter} from "../store/slices/SearchSlice";
import './UserSearchBar.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {mobile} from "../service/CssUtils";

class UserSearchBar extends Component {

    render() {
        return (
            <div className={'user-search-bar-container'}>
                <FontAwesomeIcon icon={faSearch} className={mobile('user-search-icon')}/>
                <VrgFormControl value={this.props.searchFilter} className={mobile('user-search-input')}
                                onChange={(event) => this.props.updateSearchFilter(event.target.value)}>
                </VrgFormControl>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        searchFilter: state.search.filter,
    }
}

export default connect(mapStateToProps, {
    updateSearchFilter
})(UserSearchBar)
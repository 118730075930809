import {Component} from "react";
import {VrgFormControl} from "../../widgets/VrgFormControl";
import {mobile} from "../../service/CssUtils";
import {compose} from "@reduxjs/toolkit";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {toggleCloning, updateTitle} from "../../store/slices/TierSlice";
import {VrgButton} from "../../widgets/VrgButton";
import './TierlistHeader.css';
import RandomModal from "./menus/RandomModal";

class TierlistHeader extends Component {

    state = {
        randomModalOpen: false
    }

    render() {
        return (
            <div className={mobile('tierlist-header-container')}>
                {this.state.randomModalOpen && <RandomModal onClose={() => this.setState({randomModalOpen: false})}/>}

                <VrgFormControl value={this.props.title} placeholder={'Title (optional)'}
                                className={mobile('tierlist-header-title-input')}
                                onDrop={(event) => event.preventDefault()}
                                onChange={(event) => this.props.updateTitle(event.target.value)}/>

                <div className={mobile('tierlist-header-buttons-container')}>
                    <VrgButton variant={this.props.cloningMode ? 'danger' : 'primary'}
                               onClick={() => this.props.toggleCloning(!this.props.cloningMode)}>
                        {this.props.cloningMode ? 'Stop cloning' : 'Clone user'}
                    </VrgButton>
                    <VrgButton onClick={() => this.setState({randomModalOpen: true})}>
                        Randomize
                    </VrgButton>
                </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        title: state.tier.title,
        cloningMode: state.tier.cloningMode,
    }
}

export default compose(withRouter, connect(mapStateToProps, {
    updateTitle, toggleCloning
}))(TierlistHeader)
import {Component} from "react";
import {VrgButton} from "../../../widgets/VrgButton";
import './UserBackupActions.css';
import {connect} from "react-redux";
import {ConfirmModal} from "../../../widgets/ConfirmModal";
import {baseUrl} from "../../../../config";
import {getAuthGetRequest, getAuthPostRequest} from "../../../service/FetchUtils";
import {restoreBackup} from "../../../store/slices/TierSlice";
import {StatusDisplay, StatusState} from "../../../widgets/StatusDisplay";
import {updateSetup} from "../../../store/slices/SetupSlice";
import {STORE_COMPATIBILITY_VERSION} from "../../tierlist/TierlistInitializer";

class UserBackupActions extends Component {

    state = {
        confirmModalOpen: false,
        status: '',
    }

    backupTierlist() {
        this.setState({status: StatusState.Loading});
        fetch(`${baseUrl}/users/backup`, getAuthPostRequest({
            tiers: this.props.tiers,
            title: this.props.title,
            setup: this.props.setup,
            version: STORE_COMPATIBILITY_VERSION,
        }, this.props.loggedUser))
            .then(response => response.json())
            .then(() => this.setState({status: StatusState.Done}))
            .catch((err) => {
                console.error(err);
                this.setState({status: StatusState.Errored})
            });
    }

    fetchBackup() {
        this.setState({status: StatusState.Loading})
        fetch(`${baseUrl}/users/backup`, getAuthGetRequest(this.props.loggedUser))
            .then(response => response.json())
            .then((data) => {
                if (data.version === STORE_COMPATIBILITY_VERSION) {
                    window.localStorage.setItem('state', JSON.stringify({
                        tiers: data.tiers,
                        version: STORE_COMPATIBILITY_VERSION,
                        title: data.title,
                        setup: data.setup,
                    }));
                    this.props.restoreBackup(data);
                    this.props.updateSetup(data.setup);
                    this.setState({status: StatusState.Done});
                } else {
                    console.error('Incompatible store version.');
                    this.setState({status: StatusState.Errored});
                }
            })
            .catch((err) => {
                console.error(err);
                this.setState({status: StatusState.Errored})
            });
    }

    render() {
        return (
            <div className={'user-backup-container'}>
                {this.state.confirmModalOpen &&
                    <ConfirmModal
                        onClose={() => this.setState({confirmModalOpen: false})}
                        onConfirm={() => this.fetchBackup()}
                        title={'Restore tierlist from profile'}
                        body={'You are about to restore a tierlist from your profile. ' +
                            'Doing so will erase your current tierlist. ' +
                            'Are you sure you want to do this?'}
                        buttonLabel={'Confirm'}/>
                }
                <div className={'user-backup-button-container'}>
                    <VrgButton variant={'danger'} onClick={() => this.setState({confirmModalOpen: true})}>
                        Download tierlist
                    </VrgButton>
                    <VrgButton onClick={() => this.backupTierlist()}>
                        Upload current
                    </VrgButton>
                </div>
                <StatusDisplay status={this.state.status}
                               loading={'Loading tierlist...'}
                               done={'Done!'}
                               error={'Backup Failed'}
                />
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        tiers: state.tier.tiers,
        title: state.tier.title,
        loggedUser: state.profile.loggedUser,
        setup: state.setup,
    }
}

export default connect(mapStateToProps, {restoreBackup, updateSetup})(UserBackupActions)
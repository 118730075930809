import {configureStore} from '@reduxjs/toolkit'
import {tierReducer} from "./slices/TierSlice";
import {profileReducer} from "./slices/ProfileSlice";
import {adminReducer} from "./slices/AdminSlice";
import {setupReducer} from "./slices/SetupSlice";
import {searchReducer} from "./slices/SearchSlice";

export default configureStore({
    reducer: {
        tier: tierReducer,
        search: searchReducer,
        profile: profileReducer,
        admin: adminReducer,
        setup: setupReducer,
    }
});
import {Component} from "react";
import {Button} from "react-bootstrap";
import './VrgButton.css';
import {mobile} from "../service/CssUtils";

export class VrgButton extends Component {

    render() {
        // noinspection JSValidateTypes
        return (
            <Button {...this.props}
                    className={mobile('vrg-button'
                        + (this.props.variant ? ' vrg-button-' + this.props.variant : '')
                        + (this.props.disabled ? ' vrg-button-disabled' : '')
                        + (this.props.className ? ' ' + this.props.className : ''))}/>
        );
    }

}
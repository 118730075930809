import {createSlice} from "@reduxjs/toolkit";

export const profileSlice = createSlice({
    name: 'profile',

    initialState: {
        loggedUser: undefined,
    },

    reducers: {
        login: (state, action) => {
            state.loggedUser = action.payload;
        },

        logout: (state) => {
            state.loggedUser = undefined;
        }
    }
});

export const {
    login, logout
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;
import {Component} from "react";
import {UserSettings} from "../profile/settings/UserSettings";
import {VrgPage} from "../VrgPage";
import {baseUrl} from "../../../config";
import {getAuthPostRequest} from "../../service/FetchUtils";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose} from "@reduxjs/toolkit";
import {addUser} from "../../store/slices/AdminSlice";
import {StatusState} from "../../widgets/StatusDisplay";

class UserCreationPage extends Component {

    state = {
        status: ''
    }

    componentDidMount() {
        if (!this.props.loggedUser) {
            this.props.history.push('/profile');
        }
    }

    createUser(data) {
        this.setState({status: StatusState.Loading})
        fetch(`${baseUrl}/admin/users`, getAuthPostRequest(data, this.props.loggedUser))
            .then(response => response.json())
            .then(data => {
                this.props.addUser(data);
                this.props.history.push('/admin');
            })
            .catch(() => this.setState({status: StatusState.Errored}));
    }

    render() {
        return (
            <VrgPage page={'user-create'}>
                <UserSettings
                    status={this.state.status}
                    creation={true}
                    onSuccess={(data) => this.createUser(data)}
                    user={{name: ''}}
                />
            </VrgPage>
        );
    }

}

function mapStateToProps(state) {
    return {
        loggedUser: state.profile.loggedUser
    }
}

export default compose(withRouter, connect(mapStateToProps, {addUser}))(UserCreationPage)
import {Component} from "react";
import './Tier.css';
import UserDragZone from "../user/UserDragZone";
import {TierName} from "./TierName";
import {updateTier} from "../../../store/slices/TierSlice";
import {connect} from "react-redux";

class Tier extends Component {

    render() {
        return (
            <div className={'d-flex flex-row w-100'}>
                <TierName tier={this.props.tier}
                          onLabelChange={(value) => this.props.updateTier({...this.props.tier, label: value})}
                />
                <div className={'tier-user-zone'}>
                    <UserDragZone tier={this.props.tier}/>
                </div>
            </div>
        )
    }

}

export default connect(null, {updateTier})(Tier)
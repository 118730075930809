import {Component} from "react";
import {mobile} from "../../service/CssUtils";
import {ConfirmModal} from "../../widgets/ConfirmModal";
import {VrgButton} from "../../widgets/VrgButton";
import SetupModal from "./menus/SetupModal";
import './TierlistFooter.css';
import {INITIAL_SETUP, updateSetup} from "../../store/slices/SetupSlice";
import {addTier, removeEmptyTiers, resetTiers} from "../../store/slices/TierSlice";
import {connect} from "react-redux";

class TierlistFooter extends Component {

    state = {
        setupModalOpen: false,
        deleteModalOpen: false,
    }

    resetTierlist() {
        this.props.resetTiers();
        this.props.updateSetup(INITIAL_SETUP);
        this.setState({title: ''});
    }


    render() {
        return (
            <div className={'tierlist-buttons-container'}>
                <div className={mobile('tierlist-button-group')}>
                    {this.state.deleteModalOpen &&
                        <ConfirmModal title={'Reset'}
                                      body={'This will empty and reset all tiers, and remove cloned users.'}
                                      buttonLabel={'Reset tierlist'}
                                      onClose={() => this.setState({deleteModalOpen: false})}
                                      onConfirm={() => this.resetTierlist()}
                        />
                    }
                    <VrgButton variant={'danger'} onClick={() => this.setState({deleteModalOpen: true})}>
                        Reset tierlist
                    </VrgButton>
                    <VrgButton variant={'danger'} onClick={() => this.props.removeEmptyTiers()}>
                        Remove empty
                    </VrgButton>
                    {this.state.setupModalOpen && <SetupModal onClose={() => this.setState({setupModalOpen: false})}/>}
                </div>
                <div className={mobile('tierlist-button-group')}>
                    <VrgButton onClick={() => this.setState({setupModalOpen: true})}>
                        Tierlist setup
                    </VrgButton>
                    <VrgButton onClick={() => this.props.addTier(this.props.tiers.length)}>
                        Add tier
                    </VrgButton>
                    <VrgButton onClick={() => this.props.onExport()} disabled={this.props.disableExport}>
                        Export
                    </VrgButton>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        tiers: state.tier.tiers,
    }
}

export default connect(mapStateToProps, {resetTiers, updateSetup, removeEmptyTiers, addTier})(TierlistFooter);


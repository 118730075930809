import {Component} from "react";
import TierlistHeader from "../header/TierlistHeader";
import './VrgPage.css';
import {mobile} from "../service/CssUtils";

export class VrgPage extends Component {

    render() {
        return (
            <div className={mobile('page-root')} style={{height: '100vh'}}>
                <TierlistHeader page={this.props.page}/>
                <div className={mobile('page-content-wrapper')}>
                    <div className={mobile('page-content')}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }

}
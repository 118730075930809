import {Component} from "react";
import './TierlistHeader.css';
import UserSearchBar from "./UserSearchBar";
import {VrgRouterLink} from "../widgets/VrgRouterLink";
import {connect} from "react-redux";
import {IS_DESKTOP, IS_MOBILE, mobile} from "../service/CssUtils";
import {VrgButton} from "../widgets/VrgButton";
import AdminMenuModal from "./AdminMenuModal";

class TierlistHeader extends Component {

    state = {
        menuOpen: false
    }

    render() {
        return (
            <div className={mobile('header-container')}>
                {this.state.menuOpen && <AdminMenuModal onClose={() => this.setState({menuOpen: false})}/>}
                <div className={'header-button-container'}>
                    {IS_DESKTOP ?
                        <div className={'header-title'}>/vrg/ Tierlist Maker</div> :
                        <img src={'android-chrome-192x192.png'} className={'header-logo-mobile'} alt={'logo'}/>
                    }
                    {this.props.loggedUser && this.props.loggedUser.admin && IS_MOBILE ?
                        <VrgButton className={'header-menu-button'} onClick={() => this.setState({menuOpen: true})}>
                            Navigation
                        </VrgButton>
                        :
                        <div className={'header-button-container'}>
                            <VrgRouterLink to={'/'}>
                                <div className={mobile('header-button')}>Tierlist</div>
                            </VrgRouterLink>
                            <VrgRouterLink to={'/profile'}>
                                <div className={mobile('header-button')}>Login</div>
                            </VrgRouterLink>
                            {this.props.loggedUser && this.props.loggedUser.admin &&
                                <div className={'header-admin-container'}>
                                    <VrgRouterLink to={'/admin'}>
                                        <div className={mobile('header-button')}>Admin</div>
                                    </VrgRouterLink>
                                    <VrgRouterLink to={'/user-create'}>
                                        <div className={mobile('header-button')}>Create User</div>
                                    </VrgRouterLink>
                                </div>
                            }
                        </div>
                    }

                </div>
                <UserSearchBar/>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        loggedUser: state.profile.loggedUser
    }
}

export default connect(mapStateToProps, {})(TierlistHeader)
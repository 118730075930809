import {createSlice} from "@reduxjs/toolkit";
import {v4 as uuid} from 'uuid';
import {TierColors} from "../../pages/tierlist/tier/edit/TierColors";

function getOriginalTiers() {
    return [
        {id: uuid(), label: 'S', color: 'rgb(255, 127, 127)', img: undefined, users: []},
        {id: uuid(), label: 'A', color: 'rgb(255, 191, 127)', img: undefined, users: []},
        {id: uuid(), label: 'B', color: 'rgb(255, 223, 127)', img: undefined, users: []},
        {id: uuid(), label: 'C', color: 'rgb(255, 255, 127)', img: undefined, users: []},
        {id: uuid(), label: 'D', color: 'rgb(191, 255, 127)', img: undefined, users: []},
    ];
}

function sortUsers(array) {
    array.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return +(nameA > nameB) || -(nameB > nameA)
    });
}

function generateNewTier(position) {
    return {id: uuid(), label: '', color: TierColors[position % TierColors.length], img: undefined, users: []};
}

function removeUserFromArray(array, user) {
    const index = array.findIndex(currentUser => user.id === currentUser.id);
    if (index !== -1) {
        array.splice(index, 1);
        return true;
    }
    return false;
}

export const RandomMode = {
    Random: 'random',
    First: 'first',
}

export const tierSlice = createSlice({
    name: 'tier',

    initialState: {
        storage: [],
        tiers: getOriginalTiers(),
        tierEdited: undefined,
        draggedUser: undefined,
        title: '',
        cloningMode: false,
    },

    reducers: {
        // ----------------------------------- TIER MANAGEMENT ---------------------------------------

        addTier: (state, action) => {
            state.tiers.splice(action.payload, 0, generateNewTier(state.tiers.length));
        },

        moveTier: (state, action) => {
            const {tier, direction} = action.payload;
            const index = state.tiers.findIndex(i => i.id === tier.id);
            if ((direction === 'up' && index === 0) || (direction === 'down' && index === state.tiers.length - 1)) {
                return;
            }
            state.tiers = state.tiers.filter(i => i.id !== tier.id);
            state.tiers.splice(direction === 'up' ? index - 1 : index + 1, 0, tier);
        },

        editTier: (state, action) => {
            state.tierEdited = action.payload;
        },

        updateTier: (state, action) => {
            state.tiers.splice(state.tiers.findIndex(i => i.id === action.payload.id), 1, action.payload);
            state.tierEdited = undefined;
        },

        deleteEditedTier: (state) => {
            state.tiers = state.tiers.filter(i => i.id !== state.tierEdited.id);
            state.storage = state.storage.concat(state.tierEdited.users);
            sortUsers(state.storage);
            state.tierEdited = undefined;
        },

        resetTiers: (state) => {
            let usersAgg = [];
            for (const tier of state.tiers) {
                usersAgg = usersAgg.concat(tier.users);
            }
            state.storage = state.storage.concat(usersAgg);
            state.storage = state.storage.filter(user => !user.clone);
            sortUsers(state.storage);
            state.tiers = getOriginalTiers();
            state.title = '';
        },

        restoreTiers: (state, action) => {
            state.tiers = action.payload.tiers;
        },

        setTierCount: (state, action) => {
            if (action.payload < state.tiers.length) {
                for (let i = state.tiers.length; i > action.payload; i--) {
                    state.storage = state.storage.concat(state.tiers[i - 1].users);
                }
                sortUsers(state.storage);
                state.tiers = state.tiers.slice(0, action.payload - 1);
            } else {
                for (let i = state.tiers.length; i < action.payload; i++) {
                    state.tiers.push(generateNewTier(state.tiers.length));
                }
            }
        },

        removeEmptyTiers: (state) => {
            state.tiers = state.tiers.filter(tier => tier.users.length !== 0);
        },

        // ---------------------------------- MOVING USERS ------------------------------------------

        initUsers: (state, action) => {
            state.storage = action.payload;
        },

        updateAvailableUsers: (state, action) => {
            const availableUsers = [...action.payload];
            state.tiers = state.tiers.map(tier => {
                return {
                    ...tier,
                    users: tier.users.map(user => {
                        if (user.clone) {
                            return user;
                        }
                        for (let i = 0; i < availableUsers.length; i++) {
                            if (availableUsers[i].id === user.id) {
                                // Remove users from list as we find them in existing tiers
                                return availableUsers.splice(i, 1)[0];
                            }
                        }
                        return null;
                    }).filter(user => user != null)
                }
            });
            // Put leftover users not found in the tierlist in storage
            state.storage = availableUsers;
            sortUsers(state.storage);
        },

        updateDraggedUser: (state, action) => {
            state.draggedUser = action.payload;
        },

        transferUser: (state, action) => {
            const {from, to, user} = action.payload;
            if (from === to) return;
            state.tiers = state.tiers.map(tier => {
                if (tier.id === from) {
                    removeUserFromArray(tier.users, user);
                }
                if (tier.id === to) {
                    tier.users.push(user);
                }
                return {...tier};
            });
        },

        transferUserToStorage: (state, action) => {
            const {from, user} = action.payload;
            state.storage = state.storage.concat(user);
            sortUsers(state.storage);
            state.tiers = state.tiers.map(tier => {
                if (tier.id === from) {
                    removeUserFromArray(tier.users, user);
                }
                return {...tier};
            })
        },

        transferUserFromStorage: (state, action) => {
            const {to, user} = action.payload;
            removeUserFromArray(state.storage, user);
            state.tiers = state.tiers.map(tier => {
                if (tier.id === to) {
                    tier.users.push(user);
                }
                return tier;
            })
        },

        dragUser: (state, action) => {
            if (!state.draggedUser) {
                return;
            }
            const {to, index} = action.payload;
            removeUserFromArray(state.storage, state.draggedUser);
            if (to === 'storage') {
                state.storage.splice(index, 0, state.draggedUser);
            }
            state.tiers = state.tiers.map(tier => {
                removeUserFromArray(tier.users, state.draggedUser);
                if (tier.id === to) {
                    tier.users.splice(index, 0, state.draggedUser);
                }
                return tier;
            });
        },

        updatePosition: (state, action) => {
            const {tier, user, position} = action.payload;
            state.tiers = state.tiers.map(currentTier => {
                if (currentTier.id === tier.id) {
                    const originalIndex = currentTier.users.findIndex(currentUser => currentUser.id === user.id);
                    if (originalIndex !== position) {
                        currentTier.users.splice(originalIndex, 1);
                        currentTier.users.splice(position, 0, user);
                    }
                }
                return currentTier;
            });
        },

        // ---------------------------------- BACKUP ------------------------------------------

        restoreBackup: (state, action) => {
            const {tiers, title} = action.payload;
            state.tiers = tiers;
            state.title = title;
            let idsInTiers = [];
            for (const currentTier of state.tiers) {
                idsInTiers = idsInTiers.concat(currentTier.users.map(user => user.id));
            }
            state.storage = state.storage.filter(user => !idsInTiers.includes(user.id));
        },

        // ---------------------------------- RANDOM ------------------------------------------

        randomizeUsers: (state, action) => {
            const {count, mode} = action.payload;
            if (state.tiers.length === 0) {
                return;
            }
            let randomUsers;
            if (mode === RandomMode.Random) {
                const shuffled = [...state.storage].sort(() => 0.5 - Math.random());
                randomUsers = shuffled.slice(0, count);
            } else {
                randomUsers = state.storage.slice(0, count);
            }
            for (const user of randomUsers) {
                const randomTier = state.tiers[Math.floor(Math.random() * state.tiers.length)];
                randomTier.users.push(user);
            }
            const randomIds = randomUsers.map(user => user.id);
            state.storage = state.storage.filter(user => !randomIds.includes(user.id));
        },

        // ---------------------------------- CLONING ------------------------------------------

        toggleCloning: (state, action) => {
            state.cloningMode = action.payload;
        },

        cloneUser: (state, action) => {
            for (const tier of state.tiers) {
                if (cloneUserInArray(tier.users, action.payload.id)) {
                    return;
                }
            }
            cloneUserInArray(state.storage, action.payload.id);
        },

        removeClone: (state, action) => {
            for (const tier of state.tiers) {
                if (removeUserFromArray(tier.users, action.payload)) {
                    return;
                }
            }
            removeUserFromArray(state.storage, action.payload);
        },

        // ---------------------------------- TITLE ------------------------------------------

        updateTitle: (state, action) => {
            state.title = action.payload;
        }
    }
});

function cloneUserInArray(array, userId) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === userId) {
            array.splice(i + 1, 0, {...array[i], id: uuid(), clone: true});
            return true;
        }
    }
    return false;
}

export const {
    addTier, moveTier, editTier, updateTier, deleteEditedTier, resetTiers, restoreTiers, setTierCount, removeEmptyTiers,
    initUsers, updateDraggedUser, updateAvailableUsers, dragUser,
    transferUser, transferUserToStorage, transferUserFromStorage, updatePosition,
    updateTitle, restoreBackup, randomizeUsers,
    toggleCloning, cloneUser, removeClone
} = tierSlice.actions;

export const tierReducer = tierSlice.reducer;

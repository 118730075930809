import Resizer from "react-image-file-resizer";

export function resizeFile(file, callback) {
    Resizer.imageFileResizer(
        file,
        300,
        300,
        "PNG",
        100,
        0,
        (uri) => {
            callback(uri);
        },
        "base64"
    );
}
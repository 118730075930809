import {Component, createRef} from "react";
import {VrgFormControl} from "../../../widgets/VrgFormControl";
import {FormCheck} from "react-bootstrap";
import {VrgButton} from "../../../widgets/VrgButton";
import './UserSettings.css';
import {resizeFile} from "../../../service/ImageResizer";
import {PicturePreviewer} from "../../../widgets/PicturePreviewer";
import {mobile} from "../../../service/CssUtils";
import UserBackupActions from "./UserBackupActions";
import {StatusDisplay} from "../../../widgets/StatusDisplay";

export class UserSettings extends Component {

    fileInputRef = createRef();

    state = {
        updatedUser: this.props.user,
        status: undefined,
        base64Img: undefined
    }

    loadUploadedPicture() {
        resizeFile(this.fileInputRef.current.files[0], (uri) => this.setState({base64Img: uri}));
    }

    render() {
        return (
            <div className={mobile('user-settings-container')}>
                {!this.state.creation && this.props.user.restricted &&
                    <div className={'user-settings-restricted'}>
                        You are currently restricted and cannot edit those settings.
                    </div>
                }

                <div className={'user-settings-title'}>Name</div>
                <VrgFormControl value={this.state.updatedUser.name}
                                className={'user-settings-label-input'}
                                onChange={(event) => this.setState({
                                    updatedUser: {...this.state.updatedUser, name: event.target.value}
                                })}/>
                <span className={'user-settings-subtext'}>
                    This is used for the search bar and picture hover.
                </span>
                <div className={'user-settings-spacer'}/>

                <div className={'user-settings-title'}>Picture</div>
                <PicturePreviewer user={this.props.user} img={this.state.base64Img}/>
                <VrgButton onClick={(event) => this.fileInputRef.current.click(event)}
                           className={'user-settings-upload-button'}>
                    Upload PNG
                </VrgButton>
                <input ref={this.fileInputRef} style={{display: 'none'}} accept={'.png'}
                       type="file" onChange={() => this.loadUploadedPicture()}/>
                <span className={'user-settings-subtext'}>
                    For the best result, please use a square png with transparency.
                    Your picture will be resized to 300x300.
                </span>

                {!this.props.creation &&
                    <div>
                        <div className={'user-settings-spacer'}/>
                        <FormCheck label={'Show my picture'}
                                   checked={this.state.updatedUser.active}
                                   onChange={() => {
                                       this.setState({
                                           updatedUser: {
                                               ...this.state.updatedUser,
                                               active: !this.state.updatedUser.active
                                           }
                                       });
                                   }}/>
                        <span className={'user-settings-subtext'}>
                            Unchecking this will remove you from the tierlist.
                        </span>
                    </div>
                }

                <div className={'user-settings-actions-container'}>
                    {this.props.cancelLabel &&
                        <VrgButton className={'user-settings-logout-button'} variant={'danger'}
                                   onClick={() => this.props.onCancel()}>
                            {this.props.cancelLabel}
                        </VrgButton>
                    }
                    <VrgButton className={'user-settings-save-button'}
                               disabled={this.props.user.restricted}
                               onClick={() => this.props.onSuccess({
                                   ...this.state.updatedUser,
                                   base64Img: this.state.base64Img
                               })}>
                        Save
                    </VrgButton>
                </div>
                <StatusDisplay status={this.props.status}
                               loading={'Updating...'}
                               done={'Done!'}
                               subText={'Refresh the tierlist to see your changes.'}
                               error={'Failed to update'}
                />
                {!this.props.creation &&
                    <div className={'w-100'}>
                        <div className={'user-settings-spacer'}/>
                        <div className={'user-settings-title'}>Backup</div>
                        <UserBackupActions/>
                        <span className={'user-settings-subtext'}>
                            You can upload your tierlist to your account and load it elsewhere.
                        </span>
                    </div>
                }
            </div>
        )
    }
}

import {Component} from "react";
import {Modal} from "react-bootstrap";
import {VrgButton} from "../widgets/VrgButton";
import './AdminMenuModal.css';
import {withRouter} from "react-router-dom";

class AdminMenuModal extends Component {

    openLink(uri) {
        this.props.history.push(uri);
        this.props.onClose();
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.props.onClose()} centered={true}
                   contentClassName={'admin-menu-modal-main'}
            >
                <Modal.Body>
                    <div className={'admin-menu-modal-body'}>
                        <VrgButton onClick={() => this.openLink('/')} className={'admin-menu-modal-button'}>
                            Tierlist
                        </VrgButton>
                        <VrgButton onClick={() => this.openLink('/profile')} className={'admin-menu-modal-button'}>
                            Login
                        </VrgButton>
                        <VrgButton onClick={() => this.openLink('/admin')} className={'admin-menu-modal-button'}>
                            Admin
                        </VrgButton>
                        <VrgButton onClick={() => this.openLink('/user-create')} className={'admin-menu-modal-button'}>
                            Create User
                        </VrgButton>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

}

export default withRouter(AdminMenuModal)
import {Component} from "react";
import {Modal} from "react-bootstrap";
import {VrgButton} from "../../../widgets/VrgButton";
import FormRange from "react-bootstrap/FormRange";
import {connect} from "react-redux";
import {INITIAL_SETUP, updateSetup} from "../../../store/slices/SetupSlice";
import {setTierCount} from "../../../store/slices/TierSlice";
import './SetupModal.css';

class SetupModal extends Component {

    state = {
        updatedTierCount: this.props.tiers.length,
        updatedSetup: {
            usersPerRow: this.props.setup.usersPerRow,
            columnCount: this.props.setup.columnCount,
        }
    }

    updateSetupValue(field, value) {
        this.setState({
            updatedSetup: {
                ...this.state.updatedSetup,
                [field]: value
            }
        })
    }

    saveChanges() {
        this.props.updateSetup(this.state.updatedSetup);
        this.props.setTierCount(this.state.updatedTierCount);
        this.props.onClose();
    }

    render() {
        return (
            <Modal show={true} onHide={() => this.props.onClose()} contentClassName={'setup-modal-main'} centered={true}>
                <Modal.Header className={'setup-modal-title-container'}>
                    <div className={'setup-modal-title'}>
                        Tierlist setup
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className={'setup-modal-body'}>
                        <span className={'setup-modal-form-title'}>
                            Number of tiers: {this.state.updatedTierCount}
                        </span>
                        <FormRange value={this.state.updatedTierCount} min={1} max={this.props.tiers.length + 15}
                                   className={'setup-modal-range'}
                                   onChange={(event) => this.setState({updatedTierCount: parseInt(event.target.value)})}/>
                        <span className={'setup-modal-subtext'}>
                            How many tiers should the tierlist have.
                        </span>
                        {this.state.updatedTierCount < this.props.tiers.length &&
                            <span className={'setup-modal-warning'}>
                                This will delete existing tiers at the bottom of your tierlist.
                            </span>
                        }
                        <div className={'setup-modal-spacer'}/>

                        <span className={'setup-modal-form-title'}>
                            Tier width: {this.state.updatedSetup.usersPerRow}
                        </span>
                        <FormRange value={this.state.updatedSetup.usersPerRow} min={1} max={20}
                                   className={'setup-modal-range'}
                                   onChange={(event) => this.updateSetupValue('usersPerRow', event.target.value)}/>
                        <span className={'setup-modal-subtext'}>
                            How many users will be displayed per row in a tier.
                        </span>
                        <div className={'setup-modal-spacer'}/>

                        <span className={'setup-modal-form-title'}>
                            Number of columns: {this.state.updatedSetup.columnCount}
                        </span>
                        <FormRange value={this.state.updatedSetup.columnCount} min={1} max={5}
                                   className={'setup-modal-range'}
                                   onChange={(event) => this.updateSetupValue('columnCount', event.target.value)}/>
                        <span className={'setup-modal-subtext'}>
                            How many column of tiers to show side by side.
                            Useful if your tiers do not contain a lot of users.
                        </span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={'tier-bottom-buttons-container'}>
                        <VrgButton variant="danger"
                                   onClick={() => this.setState({updatedSetup: INITIAL_SETUP, updatedTierCount: 5})}>
                            Reset to default
                        </VrgButton>
                        <div>
                            <VrgButton variant="secondary" onClick={() => this.props.onClose()}>
                                Cancel
                            </VrgButton>
                            <VrgButton className={'setup-modal-save-button'} variant="primary"
                                       onClick={() => this.saveChanges()}>
                                Save Changes
                            </VrgButton>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        tiers: state.tier.tiers,
        setup: state.setup
    }
}

export default connect(mapStateToProps, {updateSetup, setTierCount})(SetupModal)